import { useQueryClient } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { useState } from 'react';
import { useUpdateEffect } from 'react-use';
import { UmbracoTypes } from '~/lib/data-contract';
import { useBasket, useCreateBasket, usePage, useTranslations } from '~/services';
import { Button } from '~/shared/components/Button';
import { Modal } from '~/shared/components/Modal';
import { Text } from '~/shared/components/Text';
import { useBasketReference, useBasketTimer } from '~/shared/utils/storage';
import { ButtonWrapper, Container } from './styled';

export default function BasketWatcher() {
    const { data: pageType } = usePage((page) => page.type);
    const queryClient = useQueryClient();
    const [reference, setReference] = useBasketReference();
    const { translate } = useTranslations();
    const [storageUpdateTime] = useBasketTimer();
    const router = useRouter();
    const { data: basketUpdateTime } = useBasket((basket) => basket.updated.toString());
    const { mutate: createBasket } = useCreateBasket();
    const [showModal, setShowModal] = useState(false);

    // If a basketreference is present in the URL, set it as the reference else create a new basket if no reference is present.
    useUpdateEffect(() => {
        if (!reference) return createBasket();
        const basketReferenceFromQuery = router.query.basketReference as string;
        if (
            !router.isReady ||
            !basketReferenceFromQuery ||
            reference === basketReferenceFromQuery ||
            UmbracoTypes.PageTypes.IP90BasketPage !== pageType
        ) {
            return;
        }

        setShowModal(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [router, reference, pageType]);

    // Keep basket data in sync with changes from other tabs.
    useUpdateEffect(() => {
        if (storageUpdateTime === basketUpdateTime) return;
        queryClient.invalidateQueries(['basket', { reference }]);
    }, [storageUpdateTime, basketUpdateTime, queryClient, reference]);

    return (
        <Modal type="miniModal" handleClose={() => setShowModal(false)} isOpen={showModal}>
            <Container>
                <Text variant="display3">{translate('basket.openBasket.title')}</Text>
                <Text>{translate('basket.openBasket.text')}</Text>
                <ButtonWrapper>
                    <Button onClick={() => setShowModal(false)} variant="stroke">
                        {translate('generic.cancel')}
                    </Button>
                    <Button
                        onClick={() => {
                            setReference(router.query.basketReference as string);
                            setShowModal(false);
                            router.replace(
                                window.location.origin + window.location.pathname,
                                undefined,
                                { shallow: true, scroll: false },
                            );
                        }}
                    >
                        {translate('basket.openBasket.button')}
                    </Button>
                </ButtonWrapper>
            </Container>
        </Modal>
    );
}

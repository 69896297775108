import { UseComboboxPropGetters } from 'downshift';
import { useEffect } from 'react';
import { useMedia } from 'react-use';
import { Loop54Product, useTranslations, useUserProfile } from '~/services';
import { SkeletonLoader } from '~/shared/components/SkeletonLoader';
import { useScrollLock } from '~/shared/hooks';
import { queries } from '~/theme';
import { useSearchOverlay } from '../../hooks/useSearchOverlay';
import { ItemBrand, ItemProduct, ItemSuggestion, OverlayItem, OverlayItems } from '../../models';
import { BrandSuggestion } from '../BrandSuggestion';
import { ProductCard } from '../ProductCard';
import { SearchbarHeader } from '../SearchbarHeader';
import { Suggestion } from '../Suggestion';
import ScrollableBox from './ScrollAbleBox';
import { List, ListItemWrapper, ListWrapper } from './styled';
import useCanUserAccessFunctionality from '~/shared/utils/mit-fog/canUserAccessFunctionality';
import { useSession } from 'next-auth/react';

type Props = {
    data: ReturnType<typeof useSearchOverlay>['data'];
    getItemProps: UseComboboxPropGetters<OverlayItem>['getItemProps'];
    getMenuProps: UseComboboxPropGetters<OverlayItem>['getMenuProps'];
    isOpen: boolean;
    items: OverlayItems;
    onRouting: () => void;
    searchQuery: string;
    searchUrl: string | undefined;
};

export const SearchResult = ({
    data,
    getItemProps,
    getMenuProps,
    isOpen,
    items,
    onRouting,
    searchQuery,
    searchUrl,
}: Props) => {
    const { translate } = useTranslations();
    const { lock, unlock } = useScrollLock();
    const isDesktop = useMedia(queries.md, false);

    const { data: sessionData } = useSession();
    const { data: profile } = useUserProfile();
    const token = sessionData?.accessToken ?? null;
    const customerId = profile?.customer?.id ?? '';

    const suggestions = items?.filter((item) => item.type === 'suggestion') as ItemSuggestion[];
    const searchBrands = items?.filter((item) => item.type === 'brand').splice(0, 3) as ItemBrand[];
    const products = items?.filter((item) => item.type === 'product') as ItemProduct[];

    const isLoading = data.products.isLoading || data.products.isLoading || data.brands.isLoading;
    const hasResults = products.length || suggestions.length || searchBrands.length;

    const { status } = useSession();
    const isUser = useCanUserAccessFunctionality();

    useEffect(() => {
        hasResults && !isDesktop && isOpen ? lock() : unlock();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasResults, isOpen]);

    return (
        <ScrollableBox getMenuProps={getMenuProps} isOpen={isOpen}>
            {isLoading ? (
                <ListWrapper>
                    {Array.from({ length: 2 }).map((_, index) => (
                        <ListItemWrapper key={index}>
                            <SkeletonLoader height={22} width={200} key={index} />
                            <List>
                                <SkeletonLoader height={44} isFullWidth key={index} />
                            </List>
                        </ListItemWrapper>
                    ))}
                    <ListItemWrapper>
                        <SkeletonLoader height={22} width={200} />
                        <List>
                            {Array.from({ length: 4 }).map((_, index) => (
                                <SkeletonLoader height={90} isFullWidth key={index} />
                            ))}
                        </List>
                    </ListItemWrapper>
                </ListWrapper>
            ) : (
                <>
                    {hasResults ? (
                        <ListWrapper>
                            {suggestions.length || searchBrands.length ? (
                                <ListItemWrapper>
                                    <SearchbarHeader
                                        text={translate('navigation.searchbar.searchSuggestions')}
                                    />

                                    <List>
                                        {suggestions?.map((item) => (
                                            <Suggestion
                                                key={item.id}
                                                searchQuery={searchQuery}
                                                suggestion={item.text}
                                                searchUrl={searchUrl ?? ''}
                                                {...getItemProps({
                                                    item,
                                                })}
                                            />
                                        ))}
                                        {searchBrands?.map((brand) => (
                                            <BrandSuggestion
                                                key={brand.id}
                                                brand={brand.text}
                                                brandUrl={brand.data.url ?? ''}
                                                {...getItemProps({
                                                    item: brand,
                                                })}
                                                onRouting={onRouting}
                                            />
                                        ))}
                                    </List>
                                </ListItemWrapper>
                            ) : null}

                            {products.length ? (
                                <ListItemWrapper>
                                    <SearchbarHeader
                                        text={translate('navigation.searchbar.products')}
                                        search={{
                                            text: translate(
                                                'navigation.searchbar.viewAllProducts',
                                            ).replace(
                                                '[COUNT]',
                                                String(data.products?.data?.results?.count ?? 0),
                                            ),
                                            url: searchUrl ? `${searchUrl}?q=${searchQuery}` : '#',
                                            onRouting,
                                        }}
                                    />

                                    <List>
                                        {products?.map((item) => (
                                            <ProductCard
                                                key={item.id}
                                                product={item.data as Loop54Product}
                                                {...getItemProps({
                                                    item,
                                                })}
                                                showPrice={
                                                    status === 'unauthenticated' ||
                                                    !!isUser?.shouldDisplayPrices
                                                }
                                                showPriceExVat={!!isUser?.shouldDisplayPricesExVat}
                                                token={token}
                                                customerId={customerId}
                                            />
                                        ))}
                                    </List>
                                </ListItemWrapper>
                            ) : null}
                        </ListWrapper>
                    ) : (
                        <ListItemWrapper>
                            <SearchbarHeader
                                text={
                                    translate('navigation.search.noResults.title') +
                                    ' ' +
                                    searchQuery
                                }
                            />
                        </ListItemWrapper>
                    )}
                </>
            )}
        </ScrollableBox>
    );
};

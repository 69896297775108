import styled from '@emotion/styled';

export const Container = styled.div(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.space[4],
}));

export const ButtonWrapper = styled.div(({ theme }) => ({
    display: 'flex',
    gap: theme.space[4],
    justifyContent: 'flex-end',
    marginBottom: theme.space[4],

    ['& button']: {
        flex: 1,
    },
}));

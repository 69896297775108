import BasketWatcher from '~/shared/components/Basket/components/BasketWatcher/BasketWatcher';
import { useGenerateUserId } from '~/shared/hooks';
import { useWatchCookieConsent } from '~/shared/utils/cookie';

export default function Watchers() {
    useWatchCookieConsent();
    useGenerateUserId();

    return <BasketWatcher />;
}

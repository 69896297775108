import { UseComboboxPropGetters } from 'downshift';
import { useEffect, useRef } from 'react';
import { OverlayItem } from '../../models';
import { Box } from './styled';

type Props = {
    children: React.ReactNode;
    isOpen: boolean;
    getMenuProps: UseComboboxPropGetters<OverlayItem>['getMenuProps'];
};

export default function ScrollableBox({ children, isOpen, getMenuProps }: Props) {
    const boxRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const boxElement = boxRef.current;

        const handleScroll = () => {
            const activeElement = document.activeElement as HTMLElement | null;
            if (activeElement) {
                activeElement.blur();
            }
        };

        if (boxElement && isOpen) {
            boxElement!.scrollTop = 0;
            boxElement!.addEventListener('scroll', handleScroll);
        }

        return () => {
            if (boxElement) {
                boxElement!.removeEventListener('scroll', handleScroll);
            }
        };
    }, [isOpen]);

    return (
        <div {...getMenuProps()}>
            <Box isOpen={isOpen} ref={boxRef}>
                {children}
            </Box>
        </div>
    );
}

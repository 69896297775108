import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import { Link } from '~/shared/components/Link';
import { Text } from '~/shared/components/Text';
import { breakpoints } from '~/theme';

export const USPBar = styled.section(({ theme }) => ({
    position: 'relative',
    height: theme.space[5],
    width: '100%',
    overflow: 'hidden',
    gridArea: 'usp',
}));

export const List = styled.ul<{ backgroundColor: string | undefined }>(
    ({ theme, backgroundColor }) => ({
        justifyContent: 'center',
        backgroundColor: backgroundColor ?? 'transparent',
        display: 'flex',
        height: '100%',

        [breakpoints.sm]: {
            display: 'flex',
            margin: 'auto',
            gap: theme.space[12],
        },
    }),
);

export const Item = styled.li(({ theme }) => ({
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    gap: theme.space[1],
    alignItems: 'center',
    justifyContent: 'center',
}));

export const MobileItem = styled(motion.li)((props) => ({
    position: 'absolute',
    inset: 0,
    margin: 'auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

export const StyledText = styled(Text)<{ textColor: string | undefined }>(({ textColor }) => ({
    color: textColor,
}));

export const StyledLink = styled(Link)<{ textColor: string | undefined }>(
    ({ theme, textColor }) => ({
        color: textColor,
        fontSize: theme.fontSizes.beetle,
        lineHeight: theme.lineHeights.frog,
        [breakpoints.lg]: {
            fontSize: theme.fontSizes.mouse,
            lineHeight: theme.lineHeights.chicken,
        },
    }),
);
